import React from 'react'
import { Text } from 'theme-ui'
import TextList from '@components/TextList'
import { FaRegClock } from 'react-icons/fa'
import { buildResponsiveVariant as rv } from '../utils'

const monthsReplace = {
  January : 'Enero',
  February : 'Febrero',
  March : 'Marzo',
  April : 'Abril',
  May : 'Mayo',
  June : 'Junio',
  July : 'Julio',
  August : 'Agosto',
  September : 'Setiembre',
  October : 'Octubre',
  November : 'Noviembre',
  December : 'Diciembre',
}

const CardFooterInfo = ({ variant, date, timeToRead }) => {

  if ( date ) {
    Object.keys(monthsReplace).forEach( ( month ) => {
      date = date.replace( month, monthsReplace[month] )
    })
  }
  return (
    <TextList nowrap>
      {date && <Text sx={{ variant: rv(variant, 'date') }}>{date}</Text>}
      {timeToRead && (
        <Text sx={{ variant: rv(variant, 'timeToRead') }}>
          <FaRegClock css={{ verticalAlign: `middle` }} /> {timeToRead} min
        </Text>
      )}
    </TextList>
  )
}

export default CardFooterInfo
